import request from '@/utils/request.js'

// 获取全部游戏列表
export function getGameList(gameType) {
    return request({
        url: '/pmm/game/ranking/list',
        method: 'get',
        params: {
            gameType
        }
    })
}

// 获取游戏详情
export function getGameInfo(gameId) {
    return request({
        url: '/pmm/game/info',
        method: 'get',
        params: {
            gameId
        }
    })
}

// 获取游戏类型
export function getGameType() {
    return request({
        url: '/pmm/system/dict',
        method: 'get',
        params: {
            dictTypes: 'game_type'
        }
    })
}

// 随机打乱数组
export function shuffle(arr) {
    let length = arr.length,
        randomIndex,
        temp;
    while (length) {
        randomIndex = Math.floor(Math.random() * (length--));
        temp = arr[randomIndex];
        arr[randomIndex] = arr[length];
        arr[length] = temp
    }
    return arr;
}

// 判断pc/移动端
export function determinePcOrMove() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        //fontsize计算
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 320 * 16 + 'px'
        return 1
    } else {
        return 2
    }
}

/**
 * 配置 seo
 * @param title 网页标题
 * @param key 关键词
 * @param des 描述
 */
export function setMeta(key, des) {
    // let  title_default = "网站名称";
    // if(title){
    //   title_default += title;
    // }
    // document.title = title_default;
    document.querySelector("meta[name='keywords']").setAttribute('content', key);
    document.querySelector("meta[name='description']").setAttribute('content', des);
}


// 全部数据
export function getJson() {
    return (
        [{
            "gameId": 1,
            "Name": "AdamAndEveGoXmas",
            "Url": "https://www.dreamlive.pro/gcenter/games/AdamAndEveGoXmas/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/AdamAndEveGoXmas/180x180.jpg ",
            "desc": "Adam And Eve Go Xmas a puzzle-solving adventure game with cartoon pictures and interesting tasks, control the barbarian Adam, collect food and flowers, and give Eve a Christmas gift",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 1,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CAR ,ACTION ,RACING，ARCADE，ADVENTURE",
            "ImgSize": 1,
            "Flash": 1,
            "VideoUrl": "https://www.gugoplay.com/g_videos/CrazyCars.mp4"
        }, {
            "gameId": 2,
            "Name": "Bike RaceX",
            "Url": "https://www.dreamlive.pro/gcenter/games/BikeRaceX/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/BikeRaceX/180x180.jpg ",
            "desc": "The newest extreme Bike RaceX racing game is here! Grab your motorbike, strap on your helmet and grab some airtime over obstacles to beat the clock on amazing off road circuits.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 1,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "ACTION ,PARKOUR，ARCADE，ADVENTURE",
            "ImgSize": 1,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/DreadheadParkour.mp4"
        }, {
            "gameId": 3,
            "Name": "Bubble Shooter Challenge",
            "Url": "https://www.dreamlive.pro/gcenter/games/BubbleShooterChallenge/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/BubbleShooterChallenge/180x180.jpg ",
            "desc": "*A classic bubble shooter game, the gameplay is simple, shoot the bubbles of the same color onto the bubbles of the same color, if the number is more than three, then you can destroy it\n*The feedback of the game is very good, come and experience it",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 1,
            "PARKOUR": 0,
            "3D": 1,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CAR ,CASUAL ,RACING ,PARKOUR ,3D",
            "ImgSize": 1,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/DriveMad.mp4"
        }, {
            "gameId": 4,
            "Name": "Builder Idle Arcade",
            "Url": "https://www.dreamlive.pro/gcenter/games/BuilderIdleArcade/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/BuilderIdleArcade/180x180.jpg ",
            "desc": "*A game that simulates management and construction. \n*At the beginning, players can collect trees or rocks and sell them to recycling stations for money. \n*After a certain economy, they can buy wood and food processing machines. Later, they can hire workers to mine raw materials.",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 1,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CAR ,RACING，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 5,
            "Name": "Candy Bubble2",
            "Url": "https://www.dreamlive.pro/gcenter/games/CandyBubble2/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CandyBubble2/180x180.jpg ",
            "desc": "*This fun bubble shooter game is easy just to pick up and play, perfect for the whole family to play and enjoy. \n*Start the adventure now, hit and blast the balls and discover the most classic and amazing puzzle game!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 6,
            "Name": "Carrom Clash",
            "Url": "https://www.dreamlive.pro/gcenter/games/CarromClash/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CarromClash/180x180.jpg ",
            "desc": "*CarromClash multiplayer game on the board you have to be better and drop your Pool on the board before your opponent\n*you can play against any player in the world.",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "SHOOTING ,CASUAL，ARCADE，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 7,
            "Name": "Carrom Pool",
            "Url": "https://www.dreamlive.pro/gcenter/games/CarromPool/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CarromPool/180x180.jpg ",
            "desc": "*Carrom Pool is a sport-based tabletop & carrom board disc games of Indian version of pool disk game.\n*Don't miss out on this fun and relaxing carrom games!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 1,
            "Type": "BALL ,ACTION，SPORTS，ARCADE，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 8,
            "Name": "CatRoomBlast",
            "Url": "https://www.dreamlive.pro/gcenter/games/CatRoomBlast/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CatRoomBlast/180x180.jpg ",
            "desc": "*Match, earn money and stars to create your cute characters.\n*Improve your home.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "ACTION，ARCADE，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 9,
            "Name": "ChickChickenConnect",
            "Url": "https://www.dreamlive.pro/gcenter/games/ChickChickenConnect/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/ChickChickenConnect/180x180.jpg ",
            "desc": "*A game with cute style, bright colors, and classic cartoon gameplay. Elimination is done by connecting all the chickens of the same color. \n*If you eliminate a large number at one time, you can also get colored eggs. Colored eggs can be clicked to eliminate a whole circle chick",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION，ARCADE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 10,
            "Name": "ChristmasDector",
            "Url": "https://www.dreamlive.pro/gcenter/games/ChristmasDector/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/ChristmasDector/180x180.jpg ",
            "desc": "*Looking for decorations to decorate your room\n*Dress up for an unforgettable Christmas.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 11,
            "Name": "ChristmasInstagirls",
            "Url": "https://www.dreamlive.pro/gcenter/games/ChristmasInstagirls/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/ChristmasInstagirls/180x180.jpg ",
            "desc": "*Choose one of the girls characters to dress up Raven Queen, \n*Apple White, Madeline Hatter, Draculaura, Cleo de Nile, Clawdeen - monster high dress up style games, or design your own dolls",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 1,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "BALL ,GIRLS ,ACTION，ARCADE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 12,
            "Name": "CowBay",
            "Url": "https://www.dreamlive.pro/gcenter/games/CowBay/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CowBay/180x180.jpg ",
            "desc": "*A survival building game where you play as a cute cow. \n*By collecting wood to build tools such as axes, you can cut trees, use various materials to make workbenches, and then unlock and make more tools, such as pickaxes, shovels, etc. to mine more materials, survive and build and grow stronger",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/MonkeyMart.mp4"
        }, {
            "gameId": 13,
            "Name": "CrazyRacing",
            "Url": "https://www.dreamlive.pro/gcenter/games/CrazyRacing/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/CrazyRacing/180x180.jpg ",
            "desc": "*Drive your car, defeat the hostile thugs on the road, collect more money, \n*upgrade vehicles, weapons and equipment, defeat the enemy bosses, come and join this crazy racing road",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 1,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CAR ,ACTION ,RACING，ARCADE，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/MotoX3M.mp4"
        }, {
            "gameId": 14,
            "Name": "DominoFrenzy",
            "Url": "https://www.dreamlive.pro/gcenter/games/DominoFrenzy/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/DominoFrenzy/180x180.jpg ",
            "desc": "*Try to shoot the ball as smart as possible and hit the Dominos at the right spot\n*create a huge chain reaction.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION，ARCADE，ADVENTURE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/StickmanHook.mp4"
        }, {
            "gameId": 15,
            "Name": "DragRacing",
            "Url": "https://www.dreamlive.pro/gcenter/games/DragRacing/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/DragRacing/180x180.jpg ",
            "desc": "*Drive, customize your speed car and beat all of your racing rivals \n*and become the #1 from all racers with the best underground cars in his garage!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,PUZZLE",
            "ImgSize": 2,
            "Flash": "",
            "VideoUrl": "https://www.gugoplay.com/g_videos/WhoIsLying.mp4"
        }, {
            "gameId": 16,
            "Name": "EggsACars",
            "Url": "https://www.dreamlive.pro/gcenter/games/EggsACars/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/EggsACars/180x180.jpg ",
            "desc": "*Tap the right arrow button to move forward, then hit the left arrow button to \nreverse.\n*Be patient though, it's not as easy as you think.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 1,
            "Type": "SPORTS，ACTION，BALL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 17,
            "Name": "EndlessHand",
            "Url": "https://www.dreamlive.pro/gcenter/games/EndlessHand/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/EndlessHand/180x180.jpg ",
            "desc": "*A cooking-themed game. Players need to be quick-eyed and quick-handed. Every time the hand passes over the pizza, carefully confirm whether it is the ingredient that we need to sprinkle on the pizza. \n*If so, it must be passed when the hand passes over the pizza. Click to sprinkle the ingredients",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 18,
            "Name": "EpicPranks",
            "Url": "https://www.dreamlive.pro/gcenter/games/EpicPranks/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/EpicPranks/180x180.jpg ",
            "desc": "*Here is unknown and interesting, you only have weapons in your hand.\n*Meeting partners, reaping resonance, and winning, fighting is that simple.",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，CAR",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 19,
            "Name": "ExtremeBikers",
            "Url": "https://www.dreamlive.pro/gcenter/games/ExtremeBikers/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/ExtremeBikers/180x180.jpg ",
            "desc": "*It's time to take your bike off the road, customize your rider \n*and race againstyour friends in the new Multiplayer Race mode!",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "SHOOTING ,ACTION，ARCADE，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 20,
            "Name": "FantasticSniper",
            "Url": "https://www.dreamlive.pro/gcenter/games/FantasticSniper/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FantasticSniper/180x180.jpg ",
            "desc": "*Move the scope of the sniper rifle, aim at the zombies, or aim at the various mechanisms reserved for players in each level. \n*After killing all the zombies, you can pass the level. *Sometimes it is the best choice to pass the level instead of directly attacking the zombies.",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CAR ,ACTION，ARCADE，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 21,
            "Name": "FantasyMadness",
            "Url": "https://www.dreamlive.pro/gcenter/games/FantasyMadness/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FantasyMadness/180x180.jpg ",
            "desc": "*A vampire survivor game, but we are playing a druid who controls the power of nature. Every time you upgrade, \n*you can randomly choose one of the three skills to upgrade. You will face a large number of enemies. \n*Every once in a while There will also be bosses coming, making every skill upgrade ",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 22,
            "Name": "FastFurious",
            "Url": "https://www.dreamlive.pro/gcenter/games/FastFurious/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FastFurious/180x180.jpg ",
            "desc": "*Set your great name and get ready for some fun lightning speed car racing!\n*You can racing all over the world,choose your track and start racing with your opponent.",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "ACTION，SHOOTING，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 23,
            "Name": "FGLudo",
            "Url": "https://www.dreamlive.pro/gcenter/games/FGLudo/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FGLudo/180x180.jpg ",
            "desc": "*The game is played between 2 and 4 players. \n*The goal of the game is to move all four pieces to the home position in the center.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 1,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "BALL ,GIRLS ,CASUAL ,3D",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 24,
            "Name": "FireBlocks",
            "Url": "https://www.dreamlive.pro/gcenter/games/FireBlocks/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FireBlocks/180x180.jpg ",
            "desc": "*You need to shoot fruit rings of the rotate tower from a cannon. \n*Watch out for moving obstacles and don't blast them!",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 1,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CAR ,PUZZLE ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 25,
            "Name": "FlipBottle",
            "Url": "https://www.dreamlive.pro/gcenter/games/FlipBottle/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FlipBottle/180x180.jpg ",
            "desc": "*Make the bottle jump or even double flip. \n*How? Just tap the screen at the right moment and jump!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 26,
            "Name": "FourColors",
            "Url": "https://www.dreamlive.pro/gcenter/games/FourColors/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FourColors/180x180.jpg ",
            "desc": "*The classic Uno card game is now free to play, Uno and the first player who runs out of cards wins, \n*the game supports up to four players, come and try",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "BALL ,GIRLS ,CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 27,
            "Name": "FreekickTraining",
            "Url": "https://www.dreamlive.pro/gcenter/games/FreekickTraining/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/FreekickTraining/180x180.jpg ",
            "desc": "*Do you like football game? \n*Make your team a Soccer Champion 2020.",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "SHOOTING ,GIRLS ,CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 28,
            "Name": "GardenTales3",
            "Url": "https://www.dreamlive.pro/gcenter/games/GardenTales3/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/GardenTales3/180x180.jpg ",
            "desc": "*A classic matchmaking game with beautiful pictures and gameplay. \n*Click more than 2 pieces of the same color and adjacent candies to eliminate them. \n*The more eliminations in one row, the higher the score. Come and try it!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 29,
            "Name": "HappyGlass",
            "Url": "https://www.dreamlive.pro/gcenter/games/HappyGlass/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/HappyGlass/180x180.jpg ",
            "desc": "*Try to find the best way to complete each level. \n*You can come up with your own solution so be creative and don't be afraid to think out of the box!\n*Some levels might look easy but let's see if you can actually get the 3 stars.",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "SHOOTING，BALL，GIRLS，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 30,
            "Name": "HiddenMagicOG",
            "Url": "https://www.dreamlive.pro/gcenter/games/HiddenMagicOG/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/HiddenMagicOG/180x180.jpg ",
            "desc": "*Look for the various items listed in each level in the magic house. \n*Most of the items are relatively obvious and highly recognizable. \n*The knowledge you need is a pair of eyes that are good at discovering.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 31,
            "Name": "HitRunSolo",
            "Url": "https://www.dreamlive.pro/gcenter/games/HitRunSolo/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/HitRunSolo/180x180.jpg ",
            "desc": "*A vertical version of Parkour dodging, attacking enemies at the same time, improving your own level, *defeating higher-level enemies, and at the end, according to your accumulated levels, \n*you will perform a ladder challenge to determine your position",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 32,
            "Name": "InstadivaNikkeDressUp",
            "Url": "https://www.dreamlive.pro/gcenter/games/InstadivaNikkeDressUp/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/InstadivaNikkeDressUp/180x180.jpg ",
            "desc": "*Nikke's fashion dress up, dress up Nikke through your dressing aesthetics, \n*share your dressing results on social software after dressing up, \n*get everyone's likes and attention, you can try a variety of fashion styles",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "SHOOTING，GIRLS，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 33,
            "Name": "JuiceMaster",
            "Url": "https://www.dreamlive.pro/gcenter/games/JuiceMaster/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/JuiceMaster/180x180.jpg ",
            "desc": "*The gameplay is more than easy. \n*Tap to start, time your hits and slice all moving fruits at a time to make fresh juice\n*A glass of juice is made each time you finish a level.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 34,
            "Name": "KillerAssassin",
            "Url": "https://www.dreamlive.pro/gcenter/games/KillerAssassin/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/KillerAssassin/180x180.jpg ",
            "desc": "*Control the assassin and hunt down your targets one by one\n*Targets with automatic machine guns can be lurking around each corner\n*Attack them swiftly and escape without being spotted",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 1,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CAR ,BALL ,ACTION ,3D，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 35,
            "Name": "KimonoFashion",
            "Url": "https://www.dreamlive.pro/gcenter/games/KimonoFashion/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/KimonoFashion/180x180.jpg ",
            "desc": "*Dress up a series of Japanese-style scenes and characters serving and makeup, \n*such as paper cranes, porcelain, garden landscapes, various styles of kimonos, and unique Japanese traditional makeup",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL ,PUZZLE ,ACTION，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 36,
            "Name": "KnifeHit",
            "Url": "https://www.dreamlive.pro/gcenter/games/KnifeHit/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/KnifeHit/180x180.jpg ",
            "desc": "*Throw the knives into the logs to break them. \n*Slash the apples and unlock new knives.Be careful to not hit the knives or the spikes. \n*Time your actions, target carefully and become the knife master!",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "ACTION，CAR，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 37,
            "Name": "KnifeSmash2",
            "Url": "https://www.dreamlive.pro/gcenter/games/KnifeSmash2/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/KnifeSmash2/180x180.jpg ",
            "desc": "*Aim to hit the spinning circle, precision counts and don't make any mistakes\n*The more knives you dash,the harder it becomes.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 38,
            "Name": "LineBiker",
            "Url": "https://www.dreamlive.pro/gcenter/games/LineBiker/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/LineBiker/180x180.jpg ",
            "desc": "*Perform insane stunts while you cruise through the never-ending world of Rider! \n*Grab your motorcycle and start flipping like a maniac!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 39,
            "Name": "LinkLinePuzzle",
            "Url": "https://www.dreamlive.pro/gcenter/games/LinkLinePuzzle/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/LinkLinePuzzle/180x180.jpg ",
            "desc": "*Free & easy to play brain puzzle! \n*Challenging for anyone from a smart puzzler to a casual gamer.\n*STEM Challenge with GeoBoard!",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 40,
            "Name": "LudoLegend",
            "Url": "https://www.dreamlive.pro/gcenter/games/LudoLegend/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/LudoLegend/180x180.jpg ",
            "desc": "*Ludo Rules are easy for people of all ages. Ludo game is played between 2 to 4 players. \n*Each player has 4 tokens. Whoever gets all four tokens to the Home first is the winner of the ludo game.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 41,
            "Name": "MatchGood3D",
            "Url": "https://www.dreamlive.pro/gcenter/games/MatchGood3D/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/MatchGood3D/180x180.jpg ",
            "desc": "*A 3D game where you find three identical objects and eliminate them. The stacks under each layer in the 3D screen are relatively easy to see, \n*but because it is 3D, the size of the objects will affect the stacking order. \n*How many levels can you pass successfully?",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 42,
            "Name": "MineCartNoob",
            "Url": "https://www.dreamlive.pro/gcenter/games/MineCartNoob/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/MineCartNoob/180x180.jpg ",
            "desc": "*Every time it is farther than the last time, noob sits in his car, with the power of the pig, pushes the car down from a high place, \n*accelerates, flips, sprints and sees how far you can drive in the end, \n*use the collection Get gold coins to upgrade various content",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,ACTION，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 43,
            "Name": "MinecraftRunner",
            "Url": "https://www.dreamlive.pro/gcenter/games/MinecraftRunner/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/MinecraftRunner/180x180.jpg ",
            "desc": "*Noob's parkour adventure, slide left and right to operate noob's up and down travel position, \n*avoid obstacles and organs, as well as various hostile characters, \n*collect gold coins and props along the way to improve the character's ability",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 44,
            "Name": "MotoManiac2",
            "Url": "https://www.dreamlive.pro/gcenter/games/MotoManiac2/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/MotoManiac2/180x180.jpg ",
            "desc": "*Show off your awesome bike racing skills in this totally fun and competitive sports game,\n*Moto Bike Mania! There are many stages to ride through and each one offers a different challenge.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 45,
            "Name": "MysticalMixing",
            "Url": "https://www.dreamlive.pro/gcenter/games/MysticalMixing/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/MysticalMixing/180x180.jpg ",
            "desc": "*Simulated magic summoning, a realistic simulated summoning experience\n*choose different magic bottles, different materials, *put them into the magic pot for stirring, read spells, and create your own elves",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "BALL ,CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 46,
            "Name": "PerfectPiano",
            "Url": "https://www.dreamlive.pro/gcenter/games/PerfectPiano/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/PerfectPiano/180x180.jpg ",
            "desc": "*Perfect Piano with 68 different music sounds. \n*4 different instruments and 17 notes that you can play.",
            "SHOOTING": 0,
            "CAR": 1,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 1,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，CAR，RACING",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 47,
            "Name": "PixieTwinesBirth",
            "Url": "https://www.dreamlive.pro/gcenter/games/PixieTwinesBirth/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/PixieTwinesBirth/180x180.jpg ",
            "desc": "*You can make some fresh juice, give a tablet and other activities like listening to music for her baby and drinking a water, \n*Check her heartbeat using a stethoscope etc.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,PARKOUR，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 48,
            "Name": "PizzaNinjaMania",
            "Url": "https://www.dreamlive.pro/gcenter/games/PizzaNinjaMania/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/PizzaNinjaMania/180x180.jpg ",
            "desc": "*Let's become a ninja, master, hero, spy, or legend of slicing dancing fruit - the best of hyper- casual hit fruit-slicing \n*mobile with-in fruit jump and dancing by the best EDM music!",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，SHOOTING",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 49,
            "Name": "Plumber",
            "Url": "https://www.dreamlive.pro/gcenter/games/Plumber/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/Plumber/180x180.jpg ",
            "desc": "*Plumber is a free easy-to-use puzzle game with HD graphics in which your job is to connect all pipes to make a perfect pipe joints! \n*Simply turn the different pipe pieces by touching them and connect them together to form a complete pipe.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 1,
            "Type": "BALL ,CASUAL ,ACTION ,PARKOUR，SPORTS",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 50,
            "Name": "RoadTurn",
            "Url": "https://www.dreamlive.pro/gcenter/games/RoadTurn/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/RoadTurn/180x180.jpg ",
            "desc": "*Prevent traffic accidents by controlling when the cars can turn onto the highway.\n*Take care and look the road cars",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "BALL，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 51,
            "Name": "RollThisBall",
            "Url": "https://www.dreamlive.pro/gcenter/games/RollThisBall/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/RollThisBall/180x180.jpg ",
            "desc": "*To do so there's a board full of different tracks that you'll have to drag around to make the best possible track without any breaks along the way.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 1,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 0,
            "SPORTS": 1,
            "Type": "BALL ,ACTION ,PARKOUR，SPORTS，ADVENTURE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 52,
            "Name": "RoomDesign",
            "Url": "https://www.dreamlive.pro/gcenter/games/RoomDesign/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/RoomDesign/180x180.jpg ",
            "desc": "*Select the furniture you want in your very own room.\n*Decorate the room with different items to complement the furniture.\n*Position and resize the furnitures anywhere you want to create a unique style.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 1,
            "Type": "CASUAL,PUZZLE，SPORTS",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 53,
            "Name": "SaveTheGoldFish",
            "Url": "https://www.dreamlive.pro/gcenter/games/SaveTheGoldFish/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SaveTheGoldFish/180x180.jpg ",
            "desc": "*Unlock the mechanism and rescue the goldfish. \n*Each level has some bolts. You have to plan your actions reasonably, use your ingenuity, find the correct unlocking method, \n*put the water in the position of the goldfish, and save the goldfish",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 1,
            "Type": "BALL ,ACTION，SPORTS",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 54,
            "Name": "SheepNSheep",
            "Url": "https://www.dreamlive.pro/gcenter/games/SheepNSheep/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SheepNSheep/180x180.jpg ",
            "desc": "*Three identical blocks can be eliminated, the rules are very simple, the first level is also very simple, *the second level is very difficult, super difficult, extremely difficult, so difficult to kill, \n*why don't you try it",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 55,
            "Name": "SnakeAndLadders",
            "Url": "https://www.dreamlive.pro/gcenter/games/SnakeAndLadders/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SnakeAndLadders/180x180.jpg ",
            "desc": "*This Dice game is a simple race contest based on sheer luck and is popular with young kids.\n*Come and Try your lucky",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 56,
            "Name": "SnakeBattle",
            "Url": "https://www.dreamlive.pro/gcenter/games/SnakeBattle/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SnakeBattle/180x180.jpg ",
            "desc": "*This snake game is an addictive new mobile game that combines the classic game of \nsnake with elements. \n*In this game you are a snake instead of a blob and the goal is become the biggest and longest snake reptile in the game.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "GIRLS ,CASUAL ,PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 57,
            "Name": "SpotTheDifference2",
            "Url": "https://www.dreamlive.pro/gcenter/games/SpotTheDifference2/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SpotTheDifference2/180x180.jpg ",
            "desc": "*A classic spot-the-differences game, the gameplay is simple and easy to understand, \n*look for the differences in the two pictures, find all the differences and you can pass the level, there are a large number of levels in the game, who are you with a sharp eye?v",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 58,
            "Name": "StackSmash",
            "Url": "https://www.dreamlive.pro/gcenter/games/StackSmash/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/StackSmash/180x180.jpg ",
            "desc": "*You can smash, bump and bounce through revolving helix platforms to reach the end.\n*How far can you go ?",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 59,
            "Name": "SupermarketGirlCleanup",
            "Url": "https://www.dreamlive.pro/gcenter/games/SupermarketGirlCleanup/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SupermarketGirlCleanup/180x180.jpg ",
            "desc": "\"Free download “educational shopping game” for have fun. \nPlay this “superstore simulator game” and enjoy shipping exactly like a “shopping mall”.\"",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 1,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 1,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "BALL ,3D，SPORTS",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 60,
            "Name": "SuperSnipre",
            "Url": "https://www.dreamlive.pro/gcenter/games/SuperSnipre/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SuperSnipre/180x180.jpg ",
            "desc": "*A sniper shooting game. Each level has a number of targets that need to be dealt with. \n*At the same time, each level can use a variety of ways to achieve your sniper mission. \n*When you pass the level, your hit rate will be calculated, and you will become a sniper with a hundred shots.",
            "SHOOTING": 1,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 1,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "ACTION，ADVENTURE，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 61,
            "Name": "SwingManHook",
            "Url": "https://www.dreamlive.pro/gcenter/games/SwingManHook/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/SwingManHook/180x180.jpg ",
            "desc": "*Use a stretch trampoline and drawstring to get the littlester past the finish line.\n* Tapping the screen can make the released drawstring hang on the hook, and the villain can use the amplitude of the left and right swing to pass the level faster",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 1,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "SHOOTING，GIRLS，CASUAL",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 62,
            "Name": "TrollThiefStickmanPuzzle",
            "Url": "https://www.dreamlive.pro/gcenter/games/TrollThiefStickmanPuzzle/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/TrollThiefStickmanPuzzle/180x180.jpg ",
            "desc": "*Long Hand Prank Puzzle, as shown in the title \n*your hand can stretch very long, you can turn, \n*you can do many unexpected things and pranks, nonsensical and funny url",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 1,
            "ACTION": 0,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "CASUAL，PUZZLE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 63,
            "Name": "X2Blocks2048Number",
            "Url": "https://www.dreamlive.pro/gcenter/games/X2Blocks2048Number/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/X2Blocks2048Number/180x180.jpg ",
            "desc": "*There are several tracks to choose from in the game. *Each time, some relatively small numbers will be randomly selected from each level of 2048 for you, and the selected location will be launched. \n*If the conditions are met, it can be eliminated. The goal is to get as high as possible.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 1,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 0,
            "PARKOUR": 0,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 1,
            "SPORTS": 0,
            "Type": "CASUAL ,ACTION，ARCADE",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }, {
            "gameId": 64,
            "Name": "ZombieKillers",
            "Url": "https://www.dreamlive.pro/gcenter/games/ZombieKillers/",
            "iconUrl": "https://www.dreamlive.pro/g_icoimg/ZombieKillers/180x180.jpg ",
            "desc": "*Keep your finger on the trigger as you try to ensure your survival in a virus ravaged world. \n*Upgrade your skills as a survivor and zombie terminator in frontline fighting, enhance your arsenal of weapons.",
            "SHOOTING": 0,
            "CAR": 0,
            "BALL": 0,
            "GIRLS": 0,
            "CASUAL": 0,
            "PUZZLE": 0,
            "ACTION": 1,
            "RACING": 1,
            "PARKOUR": 1,
            "3D": 0,
            "ADVENTURE": 0,
            "ARCADE": 0,
            "SPORTS": 0,
            "Type": "ACTION，RACING，PARKOUR",
            "ImgSize": "",
            "Flash": "",
            "VideoUrl": ""
        }]
    )
}

// 获取游戏类型
import shooting from '@/assets/01shooting.jpg';
import car from '@/assets/02car.jpg';
import ball from '@/assets/03ball.jpg';
import girls from '@/assets/04girls.jpg';
import casual from '@/assets/05casu.jpg';
import puzzle from '@/assets/06puzzle.jpg';
import action from '@/assets/07action.jpg';
import racing from '@/assets/08racing.jpg';
import parkour from '@/assets/09parkour.jpg';
import sand from '@/assets/093d.jpg';
import adventure from '@/assets/Adventure.jpg';
import arcade from '@/assets/Arcade.jpg';
import sports from '@/assets/sports.jpg';

export function getGameTypeList() {
    let game_type = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    game_type && game_type.map(() => {
        game_type[0].iconUrl = shooting
        game_type[0].type = "SHOOTING"
        game_type[1].iconUrl = car
        game_type[1].type = "CAR"
        game_type[2].iconUrl = ball
        game_type[2].type = "BALL"
        game_type[3].iconUrl = girls
        game_type[3].type = "GIRLS"
        game_type[4].iconUrl = casual
        game_type[4].type = "CASUAL"
        game_type[5].iconUrl = puzzle
        game_type[5].type = "PUZZLE"
        game_type[6].iconUrl = action
        game_type[6].type = "ACTION"
        game_type[7].iconUrl = racing
        game_type[7].type = "RACING"
        game_type[8].iconUrl = parkour
        game_type[8].type = "PARKOUR"
        game_type[9].iconUrl = sand
        game_type[9].type = "3D"
        game_type[10].iconUrl = adventure
        game_type[10].type = "ADVENTURE"
        game_type[11].iconUrl = arcade
        game_type[11].type = "ARCADE"
        game_type[12].iconUrl = sports
        game_type[12].type = "SPORTS"
    })
    return game_type
}

// 常玩游戏列表替换
export function recentGame(element) {
    let recentGame = JSON.parse(localStorage.getItem('recentGame'))
    let index = recentGame.findIndex((item) => item.filterStatus == 0)
    if (recentGame.findIndex((item) => item.gameId == element.gameId) != -1) {
        recentGame.map((item, index) => {
            if (item.gameId == element.gameId) {
                recentGame.splice(index, 1)
                item.filterStatus = 1
                recentGame.unshift(item)
            }
        })
        localStorage.setItem('recentGame', JSON.stringify(recentGame))
        return
    }
    if (index == -1) {
        recentGame.map((item) => {
            item.filterStatus = 0
        })
        recentGame[0] = {...element, filterStatus: 1}
        localStorage.setItem('recentGame', JSON.stringify(recentGame))
    } else {
        let arr = []
        recentGame.map((item) => {
            if (item.filterStatus == 0) {
                arr.push(item)
            }
        })
        recentGame.map((item, idx) => {
            if (item.gameId == arr[arr.length - 1].gameId) {
                recentGame.splice(idx, 1)
                recentGame.unshift({...element, filterStatus: 1})
            }
        })
        localStorage.setItem('recentGame', JSON.stringify(recentGame))
    }
}